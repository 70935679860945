import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { ScheduleRequest } from "api/types/scheduleRequest";

export const patientRoutes = {
  dashboard: "/patient/dashboard",
  medicalEmergency: "/patient/medicalEmergency",
  workersCompDisclaimer: "/patient/workersCompDisclaimer",
  visitSummary: {
    path: "/patient/visitSummary",
    url(patientId?: string) {
      return {
        pathname: patientRoutes.visitSummary.path,
        search: convertToQueryString({ patientId }),
        fullUrl: `${patientRoutes.visitSummary.path}${convertToQueryString({ patientId })}`,
      };
    },
  },
  medicalHistory: {
    path: "/patient/medicalHistory",
    getUrl(payload: MedicalHistoryGetUrlType) {
      const injuryId = payload.redirectTo === "questionnaire" ? payload.injuryId : "";
      return {
        pathname: patientRoutes.medicalHistory.path,
        search: `?patientId=${payload.patientId}&injuryId=${injuryId}&redirectTo=${payload.redirectTo}`,
      };
    },
    getQueryStrings(): MedicalHistoryGetQueryStringType {
      const patientId = getQueryStringValue("patientId");
      const injuryId = getQueryStringValue("injuryId");
      const redirectTo = getQueryStringValue("redirectTo");

      if (patientId && injuryId && redirectTo === "questionnaire") {
        return { isValid: true, patientId, injuryId, redirectTo };
      } else if (
        patientId &&
        (redirectTo === "back" || redirectTo === "patientDashboard" || redirectTo === "selectBodyPart")
      ) {
        return { isValid: true, patientId, redirectTo };
      } else {
        return { isValid: false, patientId, redirectTo, injuryId };
      }
    },
  },
  selectBodyPart: {
    path: "/patient/selectBodyPart",
    url() {
      const response = {
        pathname: patientRoutes.selectBodyPart.path,
        search: ``,
        fullUrl: ``,
      };
      response.fullUrl = `${response.pathname}${response.search}`;
      return response;
    },
  },
  questionnaire: {
    path: "/patient/questionnaire",
    url(injuryId: string) {
      return {
        pathname: patientRoutes.questionnaire.path,
        search: `?injuryId=${injuryId}`,
      };
    },
  },
  schedule: {
    path: "/patient/schedule",
    getQueryStrings():
      | { type: "schedule"; injuryId: string }
      | { type: "reschedule"; patientEncounterId: string }
      | { type: "invalid" } {
      const injuryId = getQueryStringValue("injuryId");
      const patientEncounterId = getQueryStringValue("patientEncounterId");

      if (injuryId) {
        return { type: "schedule", injuryId };
      } else if (patientEncounterId) {
        return { type: "reschedule", patientEncounterId };
      } else {
        return { type: "invalid" };
      }
    },
    scheduleUrl(injuryId?: string) {
      const pathname = patientRoutes.schedule.path;
      const search = `?injuryId=${injuryId || ""}`;
      return {
        pathname,
        search,
        fullUrl: `${pathname}${search}`,
      };
    },
    rescheduleUrl(patientEncounterId?: string) {
      const pathname = patientRoutes.schedule.path;
      const search = `?patientEncounterId=${patientEncounterId || ""}`;
      return {
        pathname,
        search,
        fullUrl: `${pathname}${search}`,
      };
    },
  },
  selfPay: {
    landing: {
      pathName: "/patient/selfPay/landing",
      url(referralPartnerId?: string, returnFullUrl?: boolean) {
        const partUrl = `${patientRoutes.selfPay.landing.pathName}?referralPartnerId=${referralPartnerId || ""}`;
        const fullUrl = `${window.location.origin}${partUrl}`;
        return returnFullUrl ? fullUrl : partUrl;
      },
    },
    signup: "/patient/selfPay/signup",
    signUpComplete: "/patient/selfPay/signupComplete",
    verify: "/patient/selfPay/verify",
    pay: {
      url: "/patient/selfPay/pay",
      getNavPayload(request: ScheduleRequest) {
        const qs = `?data=${encodeURIComponent(JSON.stringify(request))}`;
        return {
          pathname: patientRoutes.selfPay.pay.url,
          search: qs,
          fullUrl: `${patientRoutes.selfPay.pay.url}${qs}`,
        };
      },
    },
    paymentSuccess: "/patient/selfPay/paymentSuccess",
  },
} as const;

export const adminRoutes = {
  settings: {
    path: "/settings",
    url() {
      return adminRoutes.settings.path;
    },
  },
  clients: {
    list: "/admin/clients/list",
    edit: {
      url: "/admin/clients/edit",
      urlFn(clientId?: string) {
        return `${adminRoutes.clients.edit.url}?clientId=${clientId || ""}`;
      },
    },
    uploadEmployees: {
      url: "/admin/clients/uploadEmployees",
      getUrl(clientId: string, clientName: string) {
        return `${adminRoutes.clients.uploadEmployees.url}?clientId=${clientId}&clientName=${clientName}`;
      },
    },
  },

  users: {
    associatedUsers: {
      edit: {
        routerUrl: "/admin/users/associatedUser/edit",
        addDependantUser({
          clientId,
          clientName,
          parentUserId,
        }: {
          clientId: string;
          clientName: string | undefined;
          parentUserId: string;
        }) {
          return `${adminRoutes.users.associatedUsers.edit.routerUrl}?clientId=${clientId}&clientName=${clientName}&parentUserId=${parentUserId}`;
        },
        editDependantUser({
          clientId,
          clientName,
          userId,
        }: {
          userId: string;
          clientId: string;
          clientName: string | undefined;
        }) {
          return `${adminRoutes.users.associatedUsers.edit.routerUrl}?clientId=${clientId}&clientName=${clientName}&userId=${userId}`;
        },
      },
    },
    list: {
      routerUrl: "/admin/users/list",
      get(clientId: string, clientName: string) {
        return {
          pathname: adminRoutes.users.list.routerUrl,
          search: `?clientId=${clientId}&clientName=${clientName}`,
          fullUrl: `${adminRoutes.users.list.routerUrl}?clientId=${clientId}&clientName=${clientName}`,
        };
      },
    },
    edit: {
      routerUrl: "/admin/users/edit",
      url({ clientId, userId }: { clientId: string; userId?: string }) {
        return `${adminRoutes.users.edit.routerUrl}?clientId=${clientId}&userId=${userId || ""}`;
      },
    },
  },
  referralPartners: {
    list: "/admin/referralPartners/list",
    edit: {
      pathName: "/admin/referralPartners/edit",
      getQueryStringValue() {
        const referralPartnerId = getQueryStringValue("referralPartnerId");
        return { referralPartnerId };
      },
      url(referralPartnerId?: string) {
        const routerNavPayload = {
          pathname: `${adminRoutes.referralPartners.edit.pathName}`,
          search: `?referralPartnerId=${referralPartnerId || ""}`,
          fullUrl: "",
        };
        routerNavPayload.fullUrl = `${routerNavPayload.pathname}${routerNavPayload.search}`;
        return routerNavPayload;
      },
    },
  },
} as const;

export const cgRoutes = {
  schedule: "/cg/schedule",
  patients: "/cg/patients",
  encounterPrintout: {
    path: "/cg/encounter/print",
    url(patientEncounterId: string) {
      return {
        pathname: cgRoutes.encounterPrintout.path,
        search: `?patientEncounterId=${patientEncounterId}`,
      };
    },
    qs() {
      const qs = getQueryStringValue("patientEncounterId");
      return qs;
    },
  },
  patientsDetails: {
    path: "/cg/patients/details",
    url(patientId: string, encounterId?: string) {
      const pathname = cgRoutes.patientsDetails.path;
      const search = `?patientId=${patientId}&encounterId=${encounterId || ""}`;

      const fullUrl = `${pathname}${search}`;
      return {
        pathname,
        search,
        fullUrl,
      };
    },
    getQueryStrings():
      | { hasAllRequiredQs: true; patientId: string; encounterId?: string }
      | { hasAllRequiredQs: false; patientId?: string; encounterId?: string } {
      const patientId = getQueryStringValue("patientId");
      const encounterId = getQueryStringValue("encounterId");

      if (patientId) {
        return { hasAllRequiredQs: true, patientId, encounterId };
      } else {
        return { hasAllRequiredQs: false, patientId, encounterId };
      }
    },
  },
  scheduleAppointment: {
    path: "/cg/scheduleAppointment",
    scheduleUrl({ injuryId }: { injuryId: string }) {
      const pathname = routes.careGivers.scheduleAppointment.path;
      const search = `?injuryId=${injuryId}`;

      const fullUrl = `${pathname}${search}`;
      return {
        pathname,
        search,
        fullUrl,
      };
    },
    rescheduleUrl({ patientEncounterId }: { patientEncounterId: string }) {
      const pathname = routes.careGivers.scheduleAppointment.path;
      const search = `?patientEncounterId=${patientEncounterId}`;
      const fullUrl = `${pathname}${search}`;
      return {
        pathname,
        search,
        fullUrl,
      };
    },
    getQueryStrings():
      | { type: "schedule"; injuryId: string }
      | { type: "reSchedule"; patientEncounterId: string }
      | { type: "invalid" } {
      const injuryId = getQueryStringValue("injuryId");
      const patientEncounterId = getQueryStringValue("patientEncounterId");
      if (isDefined(injuryId)) {
        return { type: "schedule", injuryId: injuryId };
      } else if (isDefined(patientEncounterId)) {
        return { type: "reSchedule", patientEncounterId };
      } else {
        return { type: "invalid" };
      }
    },
  },
  orders: "/cg/orders",
  results: "/cg/results",
  workersComp: "/cg/workersComp",
} as const;

export const printRoutes = {
  orders: {
    pathname: "/print/orders",
    url(patientEncounterId: string) {
      const pathname = routes.printRoutes.orders.pathname;
      const search = convertToQueryString({ patientEncounterId });
      const fullUrl = `${pathname}${search}`;

      return {
        pathname,
        search,
        fullUrl,
      };
    },
    getQueryStrings():
      | { hasAllRequiredQs: true; patientEncounterId: string }
      | { hasAllRequiredQs: false; patientEncounterId?: string } {
      const patientEncounterId = getQueryStringValue("patientEncounterId");

      if (patientEncounterId) {
        return { hasAllRequiredQs: true, patientEncounterId };
      } else {
        return { hasAllRequiredQs: false };
      }
    },
  },
};

export const routes = {
  home: "/",
  //This is shortcut route to use it in posters
  selfpay: "/selfpay",
  messages: {
    pathname: "/messages",
    queryStrings() {
      const patientId = getQueryStringValue("patientId");
      return { patientId };
    },
    url(patientId?: string) {
      const pathname = routes.messages.pathname;
      const search = convertToQueryString({ patientId });
      const fullUrl = `${pathname}${search}`;

      return {
        pathname,
        search,
        fullUrl,
      };
    },
  },
  auth: {
    login: "/auth-mdo/login",
    loginUrl(returnUrl: string = window.location.href) {
      const pathname = routes.auth.login;
      const search = convertToQueryString({ returnUrl });
      const fullUrl = `${pathname}${search}`;

      return {
        pathname,
        search,
        fullUrl,
      };
    },
  },

  library: {
    ortho: "/library/ortho",
    physicalTherapy: "/library/physicalTherapy",
  },
  patient: patientRoutes,
  admin: adminRoutes,
  careGivers: cgRoutes,
  printRoutes,
  initiateCalendarConnectUrl(returnUrl: string = window.location.href) {
    const qs = convertToQueryString({ returnUrl });
    return `/api/Cronofy/InitiateCalendarConnect${qs}`;
  },
} as const;

type MedicalHistoryGetUrlType =
  | { patientId: string; injuryId: string; redirectTo: "questionnaire" }
  | { patientId: string; redirectTo: "back" | "patientDashboard" | "selectBodyPart" };

type MedicalHistoryGetQueryStringType =
  | { isValid: true; patientId: string; injuryId: string; redirectTo: "questionnaire" }
  | { isValid: true; patientId: string; redirectTo: "back" | "patientDashboard" | "selectBodyPart" }
  | { isValid: false; patientId?: string; redirectTo?: string; injuryId?: string };
