import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { OptionType } from "@sprint1/pkg/src/form/select";
import { SelectInputFormikField } from "@sprint1/pkg/src/form/select/FormikField";
import { TranslateFnType, useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { WhenOccurred } from "api/types/whenOccurred";
import { Formik, Form } from "formik";
import { CreateInjuryRequest } from "api/types/createInjuryRequest";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { hasValue } from "@sprint1/pkg/src/utils/hasValue";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { createInjury } from "api/client/injury/createInjury";
import { useState } from "react";
import { Footer } from "components/Footer";
import { useAppRoutes } from "routes/useRoutes";
import { PageLayout } from "./components/PageLayout";
import { Section } from "./components/Section";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { useGetBodyParts } from "api/client/body/getBodyParts";
import { InjurySide } from "api/types/injurySide";
import { AlternateCare } from "api/types/alternateCare";
import { QuestionnaireVersion } from "api/types/questionnaireVersion";
import { EnumHelper } from "@sprint1/pkg/src/utils/EnumHelper";
import { InjuryType } from "api/types/injuryType";
import { useAppUser } from "common/useAppUser";

import { ReSelectFormikField } from "@sprint1/pkg/src/reSelect/FormikField";
import { Fieldset } from "@sprint1/pkg/src/form/fieldset";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { Legend } from "@sprint1/pkg/src/form/legend/Legend";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { getWhenOccurredAsOptions } from "api/types/whenOccurred.helper";
import { useFormatName } from "common/useFormatName";
import { useSearchPatients } from "api/client/user/searchPatients";
import { userFilters } from "api/helpers/searchUsers.helper";
import { Loading } from "@sprint1/pkg/src/loading";
import { useFormikContext } from "formik";
import { is } from "@sprint1/pkg/src/date/is";

enum VisitType {
  NewProblemNoInjury = 1,
  NewInjuryWithin6Weeks = 2,
  OldInjuryOver6Weeks = 3,
  FollowupVisit = 4,
}

export function SelectBodyPart() {
  const { bodyParts, injuryOccurredOptions, saving, createInjuryFn, mode } = useData();
  const routes = useAppRoutes();
  const { translate } = useTranslation();

  if (!bodyParts) {
    return <ViewPortLoading />;
  }

  return (
    <Formik
      initialValues={getInitialRequest(mode === "wc-admin")}
      onSubmit={(createInjuryRequest) => {
        return createInjuryFn(createInjuryRequest as SelectBodyPartFormValueType);
      }}
    >
      <Form>
        <div className="pb-4">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 col-lg-6">
              <PageLayout titleKey="__scheduleAppointment">
                <Section titleKey="__scheduleAppointment">
                  <div className="text-muted fs-7 pb-2">{translate("__step_x_Of_y", { x: 1, y: 3 })}</div>
                  {mode !== "patient" && <WorkersComp showIsWcClaimQuestion={mode === "mdo-caregiver"} />}

                  <Field name={key("bodyPartId")} isRequired>
                    <Label>{translate("__whatBodyPartBotheringYou")}</Label>
                    <SelectInputFormikField
                      options={[
                        { label: "", value: undefined },
                        ...bodyParts.map((p) => {
                          return { label: p.name, value: p.id };
                        }),
                      ]}
                    />
                  </Field>

                  <Field name={key("injurySide")} isRequired>
                    <Label>{translate("__whichSide")}</Label>
                    <SelectInputFormikField
                      options={EnumHelper.toTranslatedOptions({
                        enumObject: InjurySide,
                        translate,
                        translationPrefix: "__InjurySide__",
                      })}
                    />
                  </Field>

                  <Field name={key("visitType")} isRequired>
                    <Label>{translate("__whatTypeOfVisit")}</Label>
                    <SelectInputFormikField options={injuryOccurredOptions} />
                  </Field>

                  <Field name={key("alternateCare")} isRequired>
                    <Label>{translate("__whereWouldYouGoForCare")}</Label>
                    <SelectInputFormikField
                      options={EnumHelper.toTranslatedOptions({
                        enumObject: AlternateCare,
                        translate,
                        translationPrefix: "__AlternateCare__",
                      })}
                    />
                  </Field>
                </Section>
              </PageLayout>
              <Footer
                variant="cancelAndContinue"
                saveButtonProps={{
                  type: "submit",
                  showSpinner: saving,
                  disabled: saving,
                }}
                onCancelClick={() => {
                  routes.goToPatientRoutes("dashboard");
                }}
              />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

function useData() {
  const { bodyParts } = useGetBodyParts({ runOnMount: true });
  const [saving, setSaving] = useState(false);
  const { patientRoutes, go } = useAppRoutes();
  const appUser = useAppUser();
  let mode: "patient" | "wc-admin" | "mdo-caregiver" =
    appUser.isCurrentRoleDoctor || appUser.isCurrentRoleMDOrthoNurse
      ? "mdo-caregiver"
      : appUser.isCurrentRoleOnSiteNurse
      ? "wc-admin"
      : "patient";

  const toast = useToast();
  const { translate } = useTranslation();

  async function createInjuryFn(request: SelectBodyPartFormValueType) {
    toast.clear();
    if (
      !hasValue(request.bodyPartId) ||
      !hasValue(request.visitType) ||
      !hasValue(request.injurySide) ||
      !hasValue(request.alternateCare)
    ) {
      toast.error(translate("__pleaseFillInAllRequiredFields"));
      return;
    }

    try {
      setSaving(true);
      const newRequest = transformRequest(request);
      const { data: injuryId } = await createInjury({ request: newRequest });

      if (mode === "patient") {
        go(patientRoutes.questionnaire.url(injuryId));
      } else {
        go({
          ...patientRoutes.medicalHistory.getUrl({
            redirectTo: "questionnaire",
            injuryId,
            patientId: request.patientId,
          }),
          delay: true,
        });
      }
    } catch (error) {
      toast.error({ error });
    } finally {
      setSaving(false);
    }
  }

  return {
    bodyParts,
    injuryOccurredOptions: getInjuryOccurredOptions(translate),
    createInjuryFn,
    saving,
    appUser,
    mode,
  };
}

export interface SelectBodyPartFormValueType extends CreateInjuryRequest {
  visitType: VisitType;
}
const key = nameof<SelectBodyPartFormValueType>;

function getInitialRequest(isWcClaim: boolean = false): Partial<SelectBodyPartFormValueType> {
  return {
    patientId: undefined,
    bodyPartId: undefined,
    workmansComp: isWcClaim,
    injurySide: InjurySide.Unknown,
    alternateCare: AlternateCare.Unknown,
    questionnaireVersion: QuestionnaireVersion.V2,
    followupVisit: false,
    visitType: undefined,
  };
}

function getInjuryOccurredOptions(translate: TranslateFnType) {
  const options: OptionType<VisitType | undefined>[] = [{ label: "", value: undefined }];
  options.push({ label: translate("__VisitType_Followup"), value: VisitType.FollowupVisit });
  options.push({ label: translate("__VisitType_NewProblem_NoInjury"), value: VisitType.NewProblemNoInjury });
  options.push({ label: translate("__VisitType_NewInjury_Within6Weeks"), value: VisitType.NewInjuryWithin6Weeks });
  options.push({ label: translate("__VisitType_OldInjury_Over6Weeks"), value: VisitType.OldInjuryOver6Weeks });
  return options;
}

function transformRequest(request: SelectBodyPartFormValueType): SelectBodyPartFormValueType {
  const newRequest = { ...request };
  switch (request.visitType) {
    case VisitType.FollowupVisit: {
      newRequest.followupVisit = true;
      newRequest.injuryType = InjuryType.NoInjury;
      break;
    }
    case VisitType.NewInjuryWithin6Weeks: {
      newRequest.whenOccurred = WhenOccurred.Within6Weeks;
      newRequest.injuryType = InjuryType.Acute;
      break;
    }
    case VisitType.NewProblemNoInjury: {
      newRequest.whenOccurred = WhenOccurred.Within6Weeks;
      newRequest.injuryType = InjuryType.NoInjury;
      break;
    }
    case VisitType.OldInjuryOver6Weeks: {
      newRequest.whenOccurred = WhenOccurred.Over12Weeks;
      newRequest.injuryType = InjuryType.Chronic;
      break;
    }
  }
  return newRequest;
}
const name = nameof<CreateInjuryRequest>;

function WorkersComp({ showIsWcClaimQuestion }: { showIsWcClaimQuestion: boolean }) {
  const { patients } = useWorkersCompData();
  const { translate } = useTranslation();
  const format = useFormatName();
  const formikContext = useFormikContext<SelectBodyPartFormValueType>();

  if (!patients) {
    return <Loading />;
  }

  const isWorkersCompCase = formikContext?.values?.workmansComp === true;

  return (
    <>
      {isWorkersCompCase && (
        <div className="alert alert-info" role="alert">
          {translate("__WorkersCompClaimDisclaimer")}
        </div>
      )}
      <Field name={name("patientId")} isRequired>
        <Label>{translate("__patient")}</Label>
        <ReSelectFormikField
          options={patients.results.map((p) => {
            return {
              label: format.formatPatientNameDetailed(p),
              value: p.id,
            };
          })}
        />
      </Field>

      {showIsWcClaimQuestion && (
        <Fieldset>
          <Legend isRequired>{translate("__isThisWorkersCompClaim")}</Legend>

          <Field name={`${name("workmansComp")}`} isCheckOrRadio isInline>
            <Label>{translate("__yes")}</Label>
            <S1InputFormikField value={true} parse="asBoolean" type={"radio"} />
          </Field>

          <Field name={`${name("workmansComp")}`} isCheckOrRadio isInline>
            <Label>{translate("__no")}</Label>
            <S1InputFormikField value={false} parse="asBoolean" type={"radio"} />
          </Field>
        </Fieldset>
      )}

      {isWorkersCompCase && (
        <>
          <Field name={`${name("dateOfInjury")}`} isRequired>
            <Label>{translate("__dateOfInjury")}</Label>
            <S1InputFormikField type="date" />
          </Field>

          <Field name={name("explanation")} isRequired>
            <Label>{translate("__explainWhatHappened")}</Label>
            <TextAreaFormikField />
          </Field>
        </>
      )}
    </>
  );
}

function useWorkersCompData() {
  const { patients } = useSearchPatients({ runOnMount: true, request: userFilters.sortByFirstAndLastName() });

  const { translate } = useTranslation();

  return {
    patients,
    injuryOccurredOptions: getWhenOccurredAsOptions(translate),
  };
}
