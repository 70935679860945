import { fetchJson } from "@sprint1/pkg/src/fetch/fetchJson";

async function getConfigApi<T>(configUrl: string = "/config.json") {
  const { data } = await fetchJson(configUrl);
  return data as T;
}

let configLocal: ConfigType | undefined = undefined;
export async function getConfig() {
  if (configLocal === undefined) {
    configLocal = await getConfigApi<ConfigType>();
  }
  return configLocal;
}

interface ConfigType {
  version: string;
  environmentName: "DEV" | "PROD";
  logrocket: {
    projectId: string;
  };
  rootDomain: "mdorthosystems.net" | "mdortho.ai";
  identityPoolId: string;
  region: string;
  bucketName: string;
  uploadLocationPrefix: string;
}
